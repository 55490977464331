import config from "../config";
import { months } from "./DateUtils";

export const floor = (number: number, decimals: number = config.staking.decimals) => {
    return Math.floor(number * 10 ** decimals) / 10 ** decimals;
}

export const lamportsToToken = (lamports: number) => {
    return lamports / 10 ** config.staking.decimals;
};

export const poolLockDurationToDays = (duration: number) => {
    return duration / 3600 / 24;
};

export const isStakeClaimAvailable = (stake: any) => {
    const duration = stake.account.poolData.account.lockDuration;
    const endDate = new Date((stake.account.stakedTime.toNumber() + duration.toNumber()) * 1000);
    const distance = endDate.getTime() - Date.now();
    return distance <= 0;
};

export const getEstimatedEarn = (apy: any, days: any, tokens: any) => {
    return floor(tokens * apy / 100 * days / 365.25);
}

export const getCurrentStakeEarn = (stake: any) => {
    const duration = stake.account.poolData.account.lockDuration * 1000;
    const stakeDate = new Date(stake.account.stakedTime * 1000);
    const passedTime = Date.now() - stakeDate.getTime();
    if (passedTime > duration) {
        return lamportsToToken(stake.account.rewardAmount);
    }
    return lamportsToToken(stake.account.rewardAmount * passedTime / duration);
}

export const getStakingPeriod = (stake: any) => {
    const duration = stake.account.poolData.account.lockDuration;
    const durationDays = poolLockDurationToDays(duration);
    const date = new Date(stake.account.stakedTime * 1000);
    const dateStr = `${date.getDate()} ${months[date.getMonth()]}, ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
    const endDate = new Date((stake.account.stakedTime.toNumber() + duration.toNumber()) * 1000);
    const dateEndStr = `${endDate.getDate()} ${months[endDate.getMonth()]}, ${('0' + endDate.getHours()).slice(-2)}:${('0' + endDate.getMinutes()).slice(-2)}`;
    return `${durationDays} days / from ${dateStr} to ${dateEndStr}`;
}

export const getStakeEndDate = (stake: any) => {
    const duration = stake.account.poolData.account.lockDuration;
    const date = new Date(stake.account.stakedTime * 1000);
    return new Date((stake.account.stakedTime.toNumber() + duration.toNumber()) * 1000);
}

export const getRemainingStakeTime = (stake: any) => {
    const duration = stake.account.poolData.account.lockDuration;
    const endDate = new Date((stake.account.stakedTime.toNumber() + duration.toNumber()) * 1000);
    let distance = endDate.getTime() - Date.now();
    if (distance <= 0) {
        return `0 days / 0 hours / 0 mins / 0 sec`;
    }
    const days = Math.floor(distance / 3600000 / 24);
    distance -= days * 3600000 * 24;
    const hours = Math.floor(distance / 3600000);
    distance -= hours * 3600000;
    const minutes = Math.floor(distance / 60000);
    distance -= minutes * 60000;
    const seconds = Math.floor(distance / 1000);
    return `${days} days / ${hours} hours / ${minutes} mins / ${seconds} sec`;
}

export const getTokensLeftToStake = (pool: any) => {
    const rewardLeft = pool.account.amountReward - pool.account.amountRewardReserved;
    const py = pool.account.apy / 365.25 * poolLockDurationToDays(pool.account.lockDuration);
    return floor(lamportsToToken(rewardLeft * 100 / py));
}