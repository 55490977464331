import React, { lazy, Suspense } from 'react';

const LazyWallet = lazy(() => import('./WalletModal'));

const WalletModal = ({ isOpen, onRequestClose, onWalletConnected }: { isOpen: boolean, onRequestClose: Function, onWalletConnected: Function }) => (
  <Suspense fallback={null}>
    <LazyWallet onRequestClose={onRequestClose} isOpen={isOpen} onWalletConnected={onWalletConnected} />
  </Suspense>
);

export default WalletModal;
