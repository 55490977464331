import React, { lazy, Suspense } from "react";

const LazyStakingOptions = lazy(() => import("./StakingOptions"));

const StakingOptions = (
  props: JSX.IntrinsicAttributes & {
    children?: React.ReactNode;
    showWallet?: boolean;
    setShowWallet?: Function;
    stakePools: any[];
    stakePoolsLoading: boolean;
    confirmingStake: boolean;
    setConfirmingStake: (v: boolean) => void;
  }
) => (
  <Suspense fallback={null}>
    <LazyStakingOptions {...props} />
  </Suspense>
);

export default StakingOptions;
