import { clusterApiUrl, PublicKey } from "@solana/web3.js";

const dev = {
    staking: {
        cluster: clusterApiUrl('testnet'),
        programIdOld: new PublicKey('GEwKo3spSpdaF9vorAn36pBYgK6duGV2QNq9S6RHi5E5'),
        programId: new PublicKey('FXipdLYRXcz9fRvb5aF3QjtFjqJPfrMZKDpr51KGYPSs'),
        tokenMint: new PublicKey('8C96hGmwzfHznPPiviPxExuKc3MkZ3sNNZcKB9qXUDLg'), // BCAT token
        decimals: 4,
        hideStakesFromPools: [
            'dpgvfKBV8TbBFnm3Ypc8vZuXkH6HiPQjM87ZUgrcMk1',
        ],
    },
};

const test = {
    staking: {
        cluster: clusterApiUrl('testnet'),
        programIdOld: new PublicKey('GEwKo3spSpdaF9vorAn36pBYgK6duGV2QNq9S6RHi5E5'),
        programId: new PublicKey('FXipdLYRXcz9fRvb5aF3QjtFjqJPfrMZKDpr51KGYPSs'),
        tokenMint: new PublicKey('8C96hGmwzfHznPPiviPxExuKc3MkZ3sNNZcKB9qXUDLg'), // BCAT token
        decimals: 4,
        hideStakesFromPools: [
            'dpgvfKBV8TbBFnm3Ypc8vZuXkH6HiPQjM87ZUgrcMk1',
        ],
    },
}

const prod = {
    staking: {
        // cluster: 'https://solana-mainnet.g.alchemy.com/v2/Bo1_-tgf0YIV_ctYLBdAyGEJp7Axsgd-', //clusterApiUrl('mainnet-beta'),
        cluster: 'https://solitary-crimson-bird.solana-mainnet.quiknode.pro/39cd0952fe1e01cead4b69f4637ceb3b8e7d96e0/', //clusterApiUrl('mainnet-beta'),
        programIdOld: new PublicKey('2BkjfP3SzHe1sfBiYS61A6cffJtgJcTy5qaRGbhQCY22'),
        programId: new PublicKey('FXipdLYRXcz9fRvb5aF3QjtFjqJPfrMZKDpr51KGYPSs'),
        tokenMint: new PublicKey('5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N'), //Enrex Token
        decimals: 2,
        hideStakesFromPools: [
            'FWSyC2GKe5zv8tJeVFrAXerNp4ySYKMrraBwAAT6ik63',
            'HAycA7TXdAKcsptRg9tvz2ci9XtHVFNSqmX2E7rtjib5',
        ],
    },
};

const config = {
    // Add common config values here
    // Default to dev if not set
    ...(process.env.REACT_APP_ENV === "prod" ? prod : process.env.REACT_APP_ENV === "test" ? test : dev),
};

export default config;
